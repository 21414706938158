/* All route */

export const VERIFY_EMAIL = "/verify-email";
export const CONFIRM_EMAIL = "/confirm-email";
export const RESET_PWD = "/reset-your-password";
export const ACTIVE_ACCOUNT = "/account-activation";
export const LOGIN = "/login";
export const REGISTER = "/sign-up";
export const HOME_SCREEN_PAGE = "/";
export const HOW_IT_WORK = "/how-it-work";
export const DESIGNS_PAGE = "/designs";
export const CERTIFIED_CONTRACTOR = "/certified-contractor";
export const CONTACT_PAGE = "/contact";
export const GET_ESTIMATED = "/get-estimated";
export const SERVICES_REPAIR = "/services-reparation/:name";
export const SERVICES = "/nos-services";
export const PRIVACY = "/privacy";

// buyer pages
export const HOME_BUYER_PAGE = "/buyer";
export const PROJECT_IN_PROGRESS_PAGE = "/buyer/project-in-progress";
export const CREATE_PROJECT_PAGE = "/buyer/create-project";
export const EDIT_PROJECT_PAGE = "/buyer/edit-project";
export const LIST_PROJECT_PAGE = "/buyer/project-list";
export const BIDS_PROJECT_PAGE = "/buyer/bids-on-your-project";
export const PLANNING_PROJECT = "/buyer/planning-project/";
export const PROJECT_SAVE = "/buyer/projects-save";
export const CONTRACTOR_PAGE = "/buyer/contractor";
export const NOTIFICATIONS_BUYER = "/buyer/notifications";
export const SETTINGS_BUYER = "/buyer/settings";
export const PROFILE_BUYER = "/buyer/profile";

// contractor pages
export const PROJECT_EXISTING_PAGE = "/contractor/existing-projects";
export const PROJECT_PUBLISH = "/contractor/projects-publish";
export const CONTRACTOR_PLANNING_PROJECT = "/contractor/planning-project/";
export const YOUR_BIDS_PAGE = "/contractor/your-bids";
export const BOOKED_APPOINTMENTS_PAGE = "/contractor/booked-appointments";
export const COMPLETED_PROJECTS_PAGE = "/contractor/completed-projects";
export const NOTIFICATIONS_CONTRACTOR = "/contractor/notifications";
export const SETTINGS_CONTRACTOR = "/contractor/settings";
export const OFFER_SUBMIT = "/contractor/offer-submit";
export const PROFILE_CONTRACTOR = "/contractor/profile";

// admin pages
export const BUYERS_ADMIN_PAGE = "/admin/buyers";
export const CONTRACTOR_ADMIN_PAGE = "/admin/contractor";
export const BOOKING_ADMIN_PAGE = "/admin/booking";
export const PAYEMENTS_ADMIN_PAGE = "/admin/payement";
export const OFFERS_ADMIN_PAGE = "/admin/offers";
export const HOW_IT_WORK_PAGE = "/how-it-work";
export const FOOTER_PAGE = "/footer";

export const NOTIFICATION_BUYERS_PAGE = "/buyers/notification";
export const NOTIFICATION_CONTRACTORS_PAGE = "/contractor/notification";

export const BUYER_DETAILS_TASK_PAGE = "/buyer/task/details";
export const CONTRACTOR_DETAILS_TASK_PAGE = "/contractor/task/details";
export const BUYER_TASK_COMPLETED = "/buyers/task/complete";
export const BUYER_DETAILS_BID_PAGE = "/buyers/bid/details";
export const BUYER_PROJECT_BIDS = "/buyers/project/bids";
export const CONTRACTOR_DETAILS_BID_PAGE = "/contractor/bid/details";

export const CONTRACTOR_DETAIL_PROJECT_PAGE = "/contractor/project/details";
export const BUYER_DETAIL_PROJECT_PAGE = "/buyer/project/details";
export const ACCOUNT_PAGE = "/admin/contractor/account";

export const APP_ID_INSTAGRAM = "1647409755786980";
export const CLIENT_SECRET_INSTAGRAM = "3eb504639749a64bd2460707d0b254a5";

//export const API_URL = "http://127.0.0.1:4000/";
//export const FRONTEND_URL = "http://localhost:3000/";
export const FRONTEND_URL = "https://econstructiv.co/";
export const API_URL = "https://api.econstructiv.co/";

export const config = {
  headers: {
    "Access-Control-Allow-Origin": `${FRONTEND_URL}`,
    "Content-Type": "application/json",
  },
};
