import React from "react";
import logo from "../../assets/images/logo.webp";
import { FiPhoneCall } from "react-icons/fi";
import { CiLinkedin } from "react-icons/ci";
import { FaFacebook, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next"
import { BiMap } from "react-icons/bi";
import "./Footer.scss"
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "flag-icon-css/css/flag-icons.css";
import { getTranslatedServices } from "../../constant/TranslatedServices";
import { PRIVACY } from "../../constant/constant";


const FooterCommponent = () => {
    const { t } = useTranslation();
    const codeCountry = ["bj", "fr", "us", "tg", "ci", "ng"]
    
    const services = getTranslatedServices(t);
    
    return (
        <>
            <div className="SectionFooter">
                <p>Nous sommes dans 18 pays:</p>
                <ul className="SectionFooter-country">
                    {codeCountry.map(list => {
                        return <i className={`flag-icon flag-icon-${list}`} />
                    })}
                </ul>
            </div>
            <div className="Footer">
            <div className="Container flex flex-column">
                <div className="Footer-top">
                    <div className="Footer-topSocial">
                        <ul>
                            <a href=""><FaFacebookF /> </a>
                            <a href=""><FaXTwitter /> </a>
                            <a href=""><FaInstagram /> </a>
                            <a href=""><FaYoutube /> </a>
                        </ul>
                    </div>
                    <div className="Footer-center">
                        <ul className="Footer-ul">
                            <h3 className="Footer-title">Nos services</h3>
                            {services.map((list, index) => {
                                if (index > 8) {
                                    return  (
                                        <li className="Footer-li" key={index}>
                                            <a href="#">{list.name}</a>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                        <ul className="Footer-ul">
                            <h3 className="Footer-title">À propos de nous</h3>
                            <li className="Footer-li">
                                <a href="#">Qui somme-nous ?</a>
                            </li>
                             <li className="Footer-li">
                                <a href="#">Comment ça fonctionne ?</a>
                            </li>
                            <li className="Footer-li">
                                <a href="#">Contactez-nous</a>
                            </li>
                        </ul>
                        <form action="" className="Footer-form">
                            <h3 className="Footer-title">
                                Inscrivez-vous pour obtenir des informations 
                                gratuites sur les coûts du projet
                            </h3>
                            <div className="Footer-inputs">
                                <InputText placeholder="Votre adresse e-mail"/>
                                <InputText placeholder="Code postale"/>
                            </div>
                            <Button className="Footer-btnSendForm w-full" label="Envoyez" />
                        </form>
                    </div>
                </div>
                <div className="Footer-bottom">
                    <p className="Footer-copyright">
                    &copy;{(new Date()).getFullYear()} Econstructiv - Tout droit réservé
                    </p>
                    <ul className="Footer-linkBottom">
                        <a href="#">Condition d'utilisation</a>
                        <a href={PRIVACY}>Politique de confidentialité</a>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}
export default FooterCommponent;